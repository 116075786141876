import { useAuthStore } from '~~/stores/auth-store'

export default defineNuxtRouteMiddleware(async (to, from) => {
  useAuthStore().fromRedirect = undefined
  const isFromRegister = from.name === 'register' || from.name === 'register-slug'

  let fromUrl = ''
  if (to.query && to.query.request === 'install_shopify' && to.query.id) {
    fromUrl = `/?from=${encodeURIComponent(to.fullPath)}`
  }

  const appEnv = useRuntimeConfig().public.appEnv
  if (appEnv !== 'development') {
    const authKeySuffix = appEnv === 'production' ? '' : '_' + appEnv
    const authCookie = useCookie('zslt_auth_key' + authKeySuffix)
    const currentApiToken = useAuthStore().token
    if (useAuthStore().isAuthenticated && (!authCookie.value || !authCookie.value.length)) {
      useAuthStore().logout()

      if (isFromRegister) {
        return navigateTo('/register' + fromUrl)
      }

      return navigateTo('/login' + fromUrl)
    }
    if (!useAuthStore().isAuthenticated || currentApiToken !== authCookie.value) {
      try {
        await useAuthStore().loginWithApiKey(authCookie.value as string)
      } catch {
        // No action required
      }
    }
  }

  if (!useAuthStore().isAuthenticated) {
    useAuthStore().logout()

    if (isFromRegister) {
      return navigateTo('/register' + fromUrl)
    }

    return navigateTo('/login' + fromUrl)
  }
})
